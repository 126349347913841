import React, { useEffect, useRef, useState, useCallback } from "react";
import "./Dino.css";

function Dino() {
  const dinoRef = useRef();
  const cactusRef = useRef();
  const doorRef = useRef();
  const policeRef = useRef();
  const [score, setScore] = useState(0);
  const [isInvincible, setIsInvincible] = useState(false);
  const [isChasing, setIsChasing] = useState(false);
  const [collisionCount, setCollisionCount] = useState(0);
  const [currentTaxFact, setCurrentTaxFact] = useState(null);
  const [shownFacts, setShownFacts] = useState([]);
  const [background, setBackground] = useState('rural'); // State for background image
  const [hasSwitchedToUrban, setHasSwitchedToUrban] = useState(false); // State to check if background has been switched to urban

  const taxFacts = [
    "Did you know? Taxes are like a contribution everyone gives to help build things we all use, like roads, parks, and schools!",
    "Remember! Paying your taxes on time is like finishing your homework early—you won't get in trouble!",
    "Income tax is money you give to the government from what you earn, like your pocket money or when your parents earn from their jobs.",
    "Sales tax is extra money added when you buy something from a shop, like buying candy or toys. This money helps the government, too.",
    "Property taxes are paid by people who own houses or land, and this money is used to help local services like schools and fire stations.",
    "Don’t forget to file your taxes on time! It’s like turning in your school project before the deadline, so you don’t get a late fee.",
    "Corporate taxes are like income taxes, but they’re for companies. The money goes to help the country grow and improve.",
    "Capital gains tax is when you make money from selling something valuable, like a special toy or a house. The government takes a small part of that profit.",
    "Estate taxes are paid when someone passes away and leaves their things, like money or a house, to others.",
    "Tax credits are like bonus points that can help lower the amount of taxes you have to pay.",
    "A tax deduction is like getting a discount on your total taxes; it means you don't have to pay as much to the government.",
    "VAT (Value Added Tax) is extra money added to things at each step of making and selling them, like a sandwich having layers!",
    "Excise taxes are like special taxes on certain things, such as petrol or chocolate. This money also goes to the government.",
    "Payroll taxes are the money taken out of salaries to help pay for programs that take care of people, like social security for older people.",
    "Understanding tax brackets is like understanding levels in a video game—the more you earn, the more you might have to pay in taxes!"
  ];

  const showRandomTaxFact = useCallback(() => {
    const availableFacts = taxFacts.filter(fact => !shownFacts.includes(fact));
    if (availableFacts.length === 0) return; // No more facts to show

    const randomFact = availableFacts[Math.floor(Math.random() * availableFacts.length)];
    setCurrentTaxFact(randomFact);
    setShownFacts(prev => [...prev, randomFact]);

    // Display the fact for 15 seconds
    setTimeout(() => setCurrentTaxFact(null), 15000); // 15000 milliseconds = 15 seconds
  }, [shownFacts, taxFacts]);


  const jump = () => {
    if (dinoRef.current && !dinoRef.current.classList.contains("jump")) {
      dinoRef.current.classList.add("jump");
      setTimeout(() => {
        dinoRef.current.classList.remove("jump");
      }, 300);
    }
  };

  const resetCactusPosition = () => {
    if (cactusRef.current) {
      cactusRef.current.style.left = "800px";
    }
  };

  const resetDoorPosition = () => {
    if (doorRef.current) {
      doorRef.current.style.left = "800px";
    }
  };

  const resetPolicePosition = () => {
    if (policeRef.current) {
      policeRef.current.style.left = "800px";
    }
  };

  const handleDoorCollision = () => {
    if (doorRef.current) {
      const dinoTop = parseInt(
        getComputedStyle(dinoRef.current).getPropertyValue("top")
      );
      const doorLeft = parseInt(
        getComputedStyle(doorRef.current).getPropertyValue("left")
      );

      if (doorLeft < 40 && doorLeft > 0 && dinoTop >= 130) {
        setScore((prevScore) => prevScore - 100);
        setIsInvincible(true);
        resetDoorPosition();
        setTimeout(() => setIsInvincible(false), 2000);
      }
    }
  };

  const handleCactusCollision = () => {
    if (cactusRef.current) {
      const dinoTop = parseInt(
        getComputedStyle(dinoRef.current).getPropertyValue("top")
      );
      const cactusLeft = parseInt(
        getComputedStyle(cactusRef.current).getPropertyValue("left")
      );

      if (cactusLeft < 40 && cactusLeft > 0 && dinoTop >= 140) {
        setIsChasing(true);
        setCollisionCount((prevCount) => prevCount + 1);
        setTimeout(() => setIsChasing(false), 2000);
        resetCactusPosition();

        if (collisionCount + 1 >= 5) {
          alert("Game Over! You've collided too many times.");
          window.location.reload();
        }
      }
    }
  };

  useEffect(() => {
    resetCactusPosition();
    resetDoorPosition();
    resetPolicePosition();

    const gameLoop = setInterval(() => {
      if (score >= 400) {
        clearInterval(gameLoop);
        alert("Game Over! You reached 400 points.");
        window.location.reload();
        return;
      }

      const dinoTop = parseInt(
        getComputedStyle(dinoRef.current).getPropertyValue("top")
      );
      let cactusLeft = parseInt(
        getComputedStyle(cactusRef.current).getPropertyValue("left")
      );
      let doorLeft = parseInt(
        getComputedStyle(doorRef.current).getPropertyValue("left")
      );
      let policeLeft = parseInt(
        getComputedStyle(policeRef.current).getPropertyValue("left")
      );

      if (!isInvincible && cactusLeft < 40 && cactusLeft > 0 && dinoTop >= 140) {
        handleCactusCollision();
      } else {
        setScore((prevScore) => prevScore + 1);

        // Show tax fact based on score milestone
        if (score > 0 && score % 50 === 0) {
          showRandomTaxFact();
        }

        // Switch to urban background at 200 points
        if (score === 200 && !hasSwitchedToUrban) {
          setBackground('urban');
          setHasSwitchedToUrban(true);
        }

        if (cactusLeft <= -20) {
          resetCactusPosition();
        } else {
          cactusRef.current.style.left = cactusLeft - 10 + "px";
        }

        if (isChasing) {
          if (policeLeft <= -20) {
            resetPolicePosition();
          } else {
            policeRef.current.style.left = policeLeft - 10 + "px";
            if (policeLeft < 40 && policeLeft > 0) {
              setScore((prevScore) => prevScore - 100);
              resetPolicePosition();
              setIsChasing(false);
            }
          }
        }

        if (score > 0 && score % 50 === 0) {
          if (doorLeft <= -20) {
            resetDoorPosition();
          } else {
            doorRef.current.style.left = doorLeft - 5 + "px";
            if (doorLeft < 40 && doorLeft > 0) {
              setScore((prevScore) => prevScore - 100);
              resetDoorPosition();
            }
          }
        }

        handleDoorCollision();
      }
    }, 200);

    return () => clearInterval(gameLoop);
  }, [score, isInvincible, isChasing, collisionCount, handleCactusCollision, handleDoorCollision, showRandomTaxFact, hasSwitchedToUrban]);

  useEffect(() => {
    document.addEventListener("keydown", jump);
    return () => document.removeEventListener("keydown", jump);
  }, []);

  return (
    <div className="game">
      <div className={`background-image ${background}`}></div>
      <div className="score">Score: {score}</div>
      <div id="dino" ref={dinoRef}></div>
      <div id="cactus" ref={cactusRef}></div>
      <div id="door" ref={doorRef}></div>
      <div id="police" ref={policeRef}></div>
      {currentTaxFact && (
        <div className="tax-fact show">
          {currentTaxFact}
        </div>
      )}
    </div>
  );
}

export default Dino;
